<template lang="pug">
    span 
        tag(v-for="(tag,idx) of tagList" :key="idx" :value="tag" :color="getColor(tag)").tag
</template>

<script>
import Tag from "./tag";
import AppConfig from "../../../app-config";
export default {
  components: { Tag },
  name: "Tags",
  props: ["tags"],
  computed: {
    tagList() {
      return this.tags.split(":");
    }
  },
  methods: {
    getColor(tag) {
      const options = AppConfig.options.tags_options;
      const option = options.find(e => e.label == tag);
      return (option && option.color) || "aqua";
    }
  }
};
</script>

<style scoped>
.tag {
  margin-top: 5px;
}
</style>
