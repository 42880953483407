<template lang="pug">
  span(:style="'background-color:'+color").tag {{value}}
</template>

<script>
export default {
  name: "tag",
  props: ["color", "value"]
};
</script>

<style scoped>
.tag {
  display: inline-block;
  width: 100px;
  text-align: center;
  line-height: 25px;
  font-size: 9px;
  border-radius: 30px;
}
</style>
