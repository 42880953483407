<template lang="pug">
  span 
    el-button(@click="refresh" type="primary").float-right 刷新
    el-table(:data="list" height="500px")
        el-table-column(prop="creation_date" label="操作日期" width="180px" )
            template(slot-scope="scope")
                span {{ formatDateAndTime(scope.row.creation_date) }}
        el-table-column(prop="tag" label="操作"  width="120px" )
            template(slot-scope="scope")
                tags(:tags="scope.row.tag")
        el-table-column(prop="details" label="内容")
</template>

<script>
import { getActivityOrderLog } from "../../../api/order";
import { formatDateAndTime } from "../../../filters";

import Tags from "./tags";

export default {
  name: "activity-order-log",
  props: ["aco_id"],
  components: { Tags },
  data: function() {
    return {
      formatDateAndTime,
      list: []
    };
  },
  methods: {
    refresh() {
      this.getList(this.aco_id);
    },
    async getList(aco_id) {
      const res = await getActivityOrderLog({ aco_id });
      this.list = res.data.data;
    }
  },
  created() {
    this.getList(this.aco_id);
  },
  watch: {
    aco_id: async function(aco_id) {
      console.log(aco_id);
      if (!aco_id) return;
      this.getList(aco_id);
    }
  }
};
</script>

<style></style>
