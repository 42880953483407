import request from "@/utils/request";
export { getExtraHeaders } from "../utils/request";

// 取得文件列表
export function getAmazonOrderList(data) {
  return request({
    url: "/order/list-amazon-order",
    method: "post",
    data
  });
}
// 取得一个订单的详情
export function getAmazonOrderByPK(data) {
  return request({
    url: "/order/get-amazon-order-by-pk",
    method: "post",
    data
  });
}

// 更新一个订单的详情
export function updateAmazonOrderByPK(data) {
  return request({
    url: "/order/update-amazon-order-by-pk",
    method: "post",
    data
  });
}

// 删除一个订单的详情
export function deleteAmazonOrderByPK(data) {
  return request({
    url: "/order/delete-amazon-order-by-pk",
    method: "post",
    data
  });
}

// 取得异常订单列表
export function getExceptionOrderList(data) {
  return request({
    url: "/order/list-exception-order",
    method: "post",
    data
  });
}

// 导出文件列表 | 只是为了系统记录
export function exportExceptionOrderList(data) {
  return request({
    url: "/order/export-exception-order",
    method: "post",
    data
  });
}

// 取得文件列表
export function getWarrantyOrderList(data) {
  return request({
    url: "/order/list-warranty-order",
    method: "post",
    data
  });
}

// 导出文件列表 | 只是为了系统记录
export function exportWarrantyOrderList(data) {
  return request({
    url: "/order/export-warranty-order",
    method: "post",
    data
  });
}
// 取得一个订单的详情
export function getWarrantyOrderByPK(data) {
  return request({
    url: "/order/get-warranty-order-by-pk",
    method: "post",
    data
  });
}

// 更新一个订单的详情
export function updateWarrantyOrderByPK(data) {
  return request({
    url: "/order/update-warranty-order-by-pk",
    method: "post",
    data
  });
}

// 删除一个订单的详情
export function deleteWarrantyOrderByPK(data) {
  return request({
    url: "/order/delete-warranty-order-by-pk",
    method: "post",
    data
  });
}

// 取得文件列表
export function getActivityOrderList(data) {
  return request({
    url: "/order/list-activity-order",
    method: "post",
    data
  });
}

// 取得文件列表
export function exportActivityOrderList(data) {
  return request({
    url: "/order/export-activity-order",
    method: "post",
    data
  });
}

// 取得一个订单的详情
export function getActivityOrderByPK(data) {
  return request({
    url: "/order/get-activity-order-by-pk",
    method: "post",
    data
  });
}

// 更新一个订单的详情
export function updateActivityOrderByPK(data) {
  return request({
    url: "/order/update-activity-order-by-pk",
    method: "post",
    data
  });
}

// 删除一个订单的详情
export function deleteActivityOrderByPK(data) {
  return request({
    url: "/order/delete-activity-order-by-pk",
    method: "post",
    data
  });
}

// 取得一个订单的详情
export function getActivityOrderLog(data) {
  return request({
    url: "/order/list-activity-order-log",
    method: "post",
    data
  });
}

export function getUploadUrl(type) {
  return request.defaults.baseURL + "/order/upload-" + type;
}
